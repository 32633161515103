<template>
  <div  :class="isMobile == true?'colorBackgroundMobile':'colorBackgroundPC'">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" >
        <el-input
          placeholder="请输入完整机构名称"
          v-model="ruleForm.companyname"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" >
        <el-input
          placeholder="请输入完整陪练姓名"
          v-model="ruleForm.teachername"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" >
        <el-input
          placeholder="请输入完整用户姓名"
          v-model="ruleForm.studentname"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass">
        <el-select
                v-model="selectClassTypeSearch"
                clearable
                placeholder="请选择课程类型"
                style="width: 240px"
               @change="handleChangeSearch"
              >
                <el-option
                  v-for="item in classTypeSearch"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
      </el-form-item>
      <el-form-item class="inputclass" >
        <el-date-picker
          v-model="startTime"
          type="date"
          placeholder="开始日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="inputclass" >
        <el-date-picker
          v-model="endTime"
          type="date"
          placeholder="结束日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm">
          查询
        </el-button>
        <el-button @click="resetFormSearch(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <div style="padding: 10px;">
      <div style="color: red;">升级说明:</div> 
      <div style="color: red;">1、【预约管理】默认查询当天及以后数据。精准查询请输入完整且正确的查询信息。</div> 
      <div style="color: red;"> 2、【上课记录】所有查询条件为精确查询，须输入完整且准确的学生姓名或教练姓名或机构名称等，不得有错别字。
      默认排序方式为 【伴学日期】
      降序，点击每列标题栏右侧的三角形可进行升序、降序排列。如果预约较多，可以在搜索栏输入日期，进行查询。</div> 
      
     
    </div> -->

    <el-button style="margin: 10px" type="primary" @click="creatByTime()">
      创建预约（时间优先）
    </el-button>

    <!-- <el-button @click="creatByteacher()">创建预约（陪练优先）</el-button> -->
    <!-- 预约列表 -->
   <div >
    <el-table :data="tableData" style="width: 100%; height: 22vw;  overflow: hidden;">
      <!-- <el-table-column type="selection" width="55" /> -->

      <el-table-column fixed prop="date" width="80" label="序号">
        <template #default="scope"> {{ scope.$index+1 }}</template>
      </el-table-column>
      <el-table-column width="150" label="操作">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">
            修改 
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <el-table-column width="150" property="studentName" label="用户" />
      <el-table-column width="150" property="firstDate" label="伴学日期" />
      <el-table-column width="300"  label="锁定时段" >
        <template #default="scope"> {{ scope.row.testDate }}~ {{ scope.row.endTime }}</template>

      </el-table-column>
      <el-table-column width="150" property="bookName" label="课程名称"  show-overflow-tooltip/>
      <el-table-column width="150" property="teacherName" label="陪练" />
      <el-table-column width="150" property="type" label="课程分类" >
      
        <template #default="scope"> {{ scope.row.type==1?'30分钟': scope.row.type==2?'60分钟':"体验课" }}</template>

      </el-table-column>

  
      <el-table-column width="150" property="selectType" label="交付方式" />
      <el-table-column width="300" property="creatTime" label="创建时间" />
    </el-table>

 
      <el-pagination
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDatalen.length"
       @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        style="height: 45px;padding-left: 10px;"
 
      />

   </div>


    <!-- 时间优先 -->
    <el-dialog v-model="dialogVisible"   @close="handleClose" :title="title" width="1200px">
      <div>创建预约</div>

      <div>


        <div class="el-row">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            label-width="auto"
            class="el-row"
          >
            <el-form-item
              label="课程类型"
              :rules="[
                {
                  required: true,
                  message: '请选择课程类型',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="selectClassType"
                clearable
                placeholder="课程类型"
                style="width: 240px"
                @change="handleChange"
              >
                <el-option
                  v-for="item in classType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="header" label="交付方式" >
              <el-select
                v-model="selectTypeValue"
                clearable
                placeholder="交付方式"
                style="width: 240px"
              >
                <el-option
                  v-for="item in selectType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="header" label="用户姓名">
              <el-select
                v-model="selectStudentName"
                clearable
                placeholder="用户姓名"
                style="width: 240px"
                @change="handleChangeClassName"
              >
                <el-option
                  v-for="item in stundeName"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="课程名称"
              :rules="[
                {
                  required: true,
                  message: '课程名称',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="selectThree"
                clearable
                placeholder="课程名称"
                style="width: 240px"
              >
                <el-option
                  v-for="item in className"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="header" label="预约次数">
              <el-select
                v-model="selectFour"
                clearable
                placeholder="预约次数"
                style="width: 240px"
                @change="handleChangeClassNumber"
              >
                <el-option
                  v-for="item in selectNumber"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="header" label="间隔时间" >
              <el-select
                v-model="selectTimeFive"
                clearable
                placeholder="预约次数"
                style="width: 240px"
              >
                <el-option
                  v-for="item in selectTimeNumber"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="首次时间"
              :rules="[
                {
                  required: true,
                  message: '首次日期',
                  trigger: 'blur',
                },
              ]"
            >
              <el-date-picker
                v-model="firstTime"
                type="date"
                placeholder="选择日期"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item
              class="header"
              style="margin-left: 30px"
              label="练习时间"
              :rules="[
                {
                  required: true,
                  message: '练习时间',
                  trigger: 'blur',
                },
              ]"
            >
            <el-time-select
            v-model="testTime"
            style="width: 240px"
            start="00:30:00"
            step="00:30:00"
            end="23:30:00"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            placeholder="选择时间"
          />
            </el-form-item>
            <el-form-item
              class="header"
             
              label="陪练姓名"
         
            >
              <el-select
                v-model="selectTeacherValue"
                clearable
                placeholder="陪练姓名"
                style="width: 240px"
              >
                <el-option
                  v-for="item in selectTeacher"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="header"
            type="primary"
            @click="insertForm"
          >
            立即创建
          </el-button>
          <el-button @click="resetForm">重置</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 修改预约 -->
    <el-dialog v-model="updatedialogVisible"   @close="handleClose" :title="title" width="1200px">
      <div>修改预约</div>

      <div>
        <div>
          请根据序号依次录入!!！
        </div>
        <div>
          课程名称选项中的数字说明：九宫格绿色格子单词数量 / 词库单词数量（例如：100/2506，100代表绿色格子有100个单词，2506代表该词库共有2506个单词）
        </div>
       

        <div  style="margin-top: 10px" >机构名称：{{company}}</div>

        <div class="el-row">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            label-width="auto"
            class="el-row"
          >
          <el-form-item
              label="课程类型"
              :rules="[
                {
                  required: true,
                  message: '请选择课程类型',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="selectClassType"
                clearable
                placeholder="课程类型"
                style="width: 240px"
                @change="handleChange"
              >
                <el-option
                  v-for="item in classType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="header" label="交付方式" >
              <el-select
                v-model="selectTypeValue"
                clearable
                placeholder="交付方式"
                style="width: 240px"
              >
                <el-option
                  v-for="item in selectType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="header" label="用户姓名">
              <el-select
                v-model="selectStudentName"
                clearable
                placeholder="用户姓名"
                style="width: 240px"
                @change="handleChangeClassName"
              >
                <el-option
                  v-for="item in stundeName"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="课程名称"
              :rules="[
                {
                  required: true,
                  message: '课程名称',
                  trigger: 'blur',
                },
              ]"
            >
              <el-select
                v-model="selectThree"
                clearable
                placeholder="课程名称"
                style="width: 240px"
              >
                <el-option
                  v-for="item in className"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="首次时间"
              style="margin-left: 20px"
              :rules="[
                {
                  required: true,
                  message: '首次日期',
                  trigger: 'blur',
                },
              ]"
            >
              <el-date-picker
                v-model="firstTime"
                type="date"
                placeholder="选择日期"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item
              class="header"
              style="margin-left: 20px"
              label="练习时间"
              :rules="[
                {
                  required: true,
                  message: '练习时间',
                  trigger: 'blur',
                },
              ]"
            >
            <el-time-select
            v-model="testTime"
            style="width: 240px"
            start="00:30:00"
            step="00:30:00"
            end="23:30:00"
            format="HH:mm:ss"
            value-format="HH:mm:ss"
            placeholder="选择时间"
          />
            </el-form-item>
            <el-form-item
    
              label="陪练姓名"
         
            >
              <el-select
                v-model="selectTeacherValue"
                clearable
                placeholder="陪练姓名"
                style="width: 240px"
              >
                <el-option
                  v-for="item in selectTeacher"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                 
                />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="header"
            type="primary"
            @click="updateForm"
          >
            修改
          </el-button>
          <el-button @click="resetForm">重置</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
   
    <script>
//  import { reactive, ref } from 'vue'   
// import { ref } from "vue";insertClassNameList
import {
   getStudentMaterialList,getbookall,getClassNameList ,updateClassNameList,
  deleteClassNameList,insertClassNameList,
  getStudentClassList,updateReadClassList} from "../../utils/api";

export default {
  name: "MANAGEMENT",

  methods: {
   
    handleClose(){
      this.resetForm() 
    },

    handleClick(value){
      if(value=="true"){
        alert('Div被点击了！');
      }
     
    },
    deleteRow(index) {
      console.log(index);
    },
    // 修改预约
    updateForm(){
      var that = this
      console.log(this.selectStudentName, this.updatestudentphone )
      var number = 0
      for (var i=0;i<this.classType.length;i++){
        console.log("label ===  ",this.classType[i]["label"],this.selectClassType)
        if(this.selectClassType == this.classType[i]["label"]){
        
          number =  parseInt( this.classType[i]["value"])
        
        }
      }
     
      updateClassNameList({ 
        type:number ,
        selectTypeValue:this.selectTypeValue ,
        teacherphone:this.selectTeacherValue,
        studentName:this.selectStudentName,
        studentphone:this.updatestudentphone,
        bookName:this.selectThree,
        firstDate:this.firstTime,
        testDate:this.testTime,
        id:this.updateManagement["id"]
      
       })
      .then((response) => 
       
      {
        if(response.data == "success"){
          that.updatedialogVisible = false
          that.resetForm()
          location.reload()
        }
      }
      
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    },
    // 创建预约
    insertForm(){
    
      this.getNowTime()
      //  结束时间 = 开始时间+间隔*次数
      for(var i =0;i<this.selectTeacher.length;i++){
        if(this.selectTeacher[i].value ==  this.selectTeacherValue){
          
          this.selectTeacherName = this.selectTeacher[i].label
        }
      }   
      for(var l =0;l<this.stundeName.length;l++){
        if(this.stundeName[l].value ==  this.selectStudentName){
          this.selectStudentPhone = this.stundeName[l].label
        }
      }   
   
      let date1 = new Date(this.firstTime).getTime() + 3600 * 1000 * 24*this.selectTimeFive*(this.selectFour-1);
      var finishtime = this.timestampToTime(date1).substring(0,10) // 增加天数
      this.userDetail = JSON.parse(sessionStorage.getItem("login")) 

    

      for (var m =0;m<this.className.length;m++){
         if(this.selectThree==this.className[m].label&&this.selectClassType=='30分钟'){
          this.classMoney = this.className[m].threeMoney
         }
         if(this.selectThree==this.className[m].label&&this.selectClassType=='60分钟'){
          this.classMoney = this.className[m].sixMoney
         }
         if(this.selectThree==this.className[m].label&&this.selectClassType=='体验课'){
          this.classMoney =  this.className[m].tiralClassMoney
          console.log("====this.className[m].tiralClassMoney====",this.className[m])
         }
      }
  
      this.dialogVisible = false

  if(this.selectThree.includes('阅读')){

    updateReadClassList({ 
        grade:this.selectThree,
        phone: this.selectStudentName,
        number:this.selectFour
       })
      .then((response) => 
       
      {
  
          console.log(response)
        
      }
      
    )
      .catch(function (error) {
        // 请求失败处理
        //alert('数据填写错误预约失败！')
        console.log(error);
      })
  }
    let param = {
            type:this.selectClassType ,
            selectTypeValue:this.selectTypeValue ,
            teacherName:this.selectTeacherName,
            teacherPhone:this.selectTeacherValue,
            studentPhone: this.selectStudentName,
            studentName:this.selectStudentPhone ,
            bookName:this.selectThree,
            firstDate:this.firstTime,
            endDate:finishtime,
            time:this.selectTimeFive,
            testDate:this.testTime,
            money:this.classMoney,
            state:0,
            companyName:this.userDetail[0].companyName,
            number:this.selectFour,
            creatTime:this.creatTime
    }
 
   
       insertClassNameList(param)
      .then((response) => 
       
      {
        if(response.code ==200){
          
          console.log(response.data.length)
          this.tableData =response.data,
          this.tableDatalen = response.data
          // location.reload()
          this.selectClassType ='' ,
          this.selectTypeValue ='' ,
          this.selectTeacherName='' ,
          this.selectTeacherValue='' ,
          this.selectStudentPhone='' ,
          this.selectStudentName='' ,
          this.selectThree='' ,
          this.firstTime='' ,
          this.testTime='' ,
          this.selectFour='' 
        }
      }
      
    )
      .catch(function (error) {
        // 请求失败处理
       // alert('数据填写错误预约失败！')
        console.log(error);
      });
       

    },
    // 获取课程名
    handleChangeClassName(value){
     var that = this
     that.className=[]
   
     getbookall({ name: value })
      .then((response) => {
     
        
    
      for(var i=0;i<response.data.length;i++){
   
        that.className.push({
          value: i,
          label: response.data[i].name,
          sixMoney: response.data[i].sixMoney,
          threeMoney:response.data[i].threeMoney,
          tiralClassMoney:response.data[i].tiralClassMoney,
          
        })
      

       }
     
       console.log('name  success ======== ',  that.className)
      }
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
     
    },
  //  组合学生姓名
    handleChangeSearch(value){
    
      this.selectClassTypeSearch = value
      
   
    },
    handleChangeClassNumber(value){
   
      var detailstudent = []
      for (var i =0;i<this.student.length;i++){
        
        if(this.student[i].phone == this.selectStudentName){
        
          detailstudent = this.student[i]
        }
    
      }

      getStudentClassList({ 
        phone:detailstudent.phone,
        selectType:this.selectClassType
      
       })
      .then((response) => 
      {
       
        this.selectClassNumber =Number( response.data)
        console.log("123 === ", this.selectClassNumber);
        console.log("234 === ",this.selectClassType);
        
        // thirtyClass  trialClass sixClass
         if (Number( this.selectClassNumber) ==Number(detailstudent.trialClass)&&this.selectClassType=='体验课'){
          alert("已有同类型课程预约"+ this.selectClassNumber+"次，用户剩余同类课程数量不足！")
          this.selectFour=0
    
        }

        if (Number( this.selectClassNumber) ==Number(detailstudent.thirtyClass)&&this.selectClassType=='30分钟'){
          alert("已有同类型课程预约"+ this.selectClassNumber+"次，用户剩余同类课程数量不足！")

          this.selectFour=0
    
      
        }

        if (Number( this.selectClassNumber) ==Number(detailstudent.sixClass)&&this.selectClassType=='60分钟'){
          alert("已有同类型课程预约，用户剩余同类课程数量不足！")
          this.selectFour=0
        
      
        }

     
     
      }
      
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });    

  

      if(Number(detailstudent.number) < Number(value)&&this.selectClassType!='体验课'){
       
        alert("预约次数大于用户剩余陪练次数！")
        
       
        this.selectFour=0
      }

      //  
      


      if(Number(this.selectClassType.number) < Number(value)&&this.selectClassType=='体验课'){
        alert("预约次数大于用户剩余陪练次数！")
        this.selectFour=0
      }



        if(this.selectClassType == '30分钟' && Number(detailstudent.thirtyClass) < Number(value)){
       
           alert("预约次数大于用户剩余30课程次数请注意！")
           this.selectFour=0
          
        }
       
       


        if(this.selectClassType == '60分钟'&& Number(detailstudent.sixClass ) < Number(value)){
          alert("预约次数大于剩余60课程次数请注意！")
          this.selectFour=0

        }
        if(this.selectClassType == '体验课'&& Number(detailstudent.trialClass) < Number(value)){
          alert("预约次数大于剩余体验课课程次数请注意！")
          this.selectFour=0
        }

    },
    handleChange(value) {
     this.selectStudent =[]
     this.stundeName=[]
     console.log("student === ",this.student)
     this.selectStudentName =''
     this.selectOne = value
      for(var i=0;i<this.student.length;i++){
       
        if (value == "30分钟"&&this.student[i].thirtyClass!=0&&this.student[i].number!=0){
          this.selectStudent.push(this.student[i])
        }
        if (value == "60分钟"&&this.student[i].sixClass!=0&&this.student[i].number!=0){
          this.selectStudent.push(this.student[i])
        }
        if (value == "体验课"&&this.student[i].trialClass!=0){
          this.selectStudent.push(this.student[i])
        }

      }
      
      for(var n=0;n<this.selectStudent.length;n++){

        this.stundeName.push( {
          value: this.selectStudent[n].phone,
          label: this.selectStudent[n].name,
        },)

      }
      

      // 这里可以执行其他逻辑
    },
    timestampToTime(timestamp) {
      if (String(timestamp).length == 10) {
        var unixtimestamp = new Date(timestamp * 1000);
        var year = 1900 + unixtimestamp.getYear();
        var month = "0" + (unixtimestamp.getMonth() + 1);
        var date = "0" + unixtimestamp.getDate();
        return (
          year +
          "-" +
          month.substring(month.length - 2, month.length) +
          "-" +
          date.substring(date.length - 2, date.length)
        );
      } else {
        let currentTime = new Date(timestamp);
        let year = currentTime.getFullYear(),
          month = currentTime.getMonth() + 1,
          day = currentTime.getDate();

        let months = month < 10 ? "0" + month : month,
          days = day < 10 ? "0" + day : day;

        return year + "-" + months + "-" + days;
      }
    },
    //显示当前时间
    getNowTime() {
  
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = now.getMonth() + 1, //得到月份
        date = now.getDate(); //得到日期
      var hour = now.getHours(); //获取当前小时数(0-23)
      var minute = now.getMinutes(); //获取当前分钟数(0-59)
      var second = now.getSeconds(); //获取当前秒数(0-59)
      var time =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      this.creatTime = time;
    },
    goLast(index) {
      if (index === 0) {
        let date1 = new Date(this.selectDate).getTime() - 3600 * 1000 * 24;
        this.selectDate = this.timestampToTime(date1).substring(0,10);
      } else if (index === 1) {
         let date2 = new Date(this.selectDate2).getTime() - 3600 * 1000 * 24;
        this.selectDate2 = this.timestampToTime(date2).substring(0,10);
      }
      else if (index === 7) {
         let date2 = new Date(this.selectDate2).getTime() - 3600 * 1000 * 24*7;
        this.selectDate2 = this.timestampToTime(date2).substring(0,10);
      }
    },
 
    //下一日
    goNextDay(index) {
      if (index === 0) {
        let date3 = new Date(this.selectDate).getTime() + 3600 * 1000 * 24;
        this.selectDate = this.timestampToTime(date3).substring(0,10);
      } else if (index === 1) {
        let date4 = new Date(this.selectDate2).getTime() + 3600 * 1000 * 24;
        this.selectDate2 = this.timestampToTime(date4).substring(0,10);
      }
      else if (index === 7) {
        let date4 = new Date(this.selectDate2).getTime() + 3600 * 1000 * 24*7;
        this.selectDate2 = this.timestampToTime(date4).substring(0,10);
      }
    },
    resetFormSearch(){

      this.ruleForm.companyname='',
      this.startTime='',
      this.endTime='',
      this.ruleForm.type='' ,
      this.ruleForm.studentname='',
      this.ruleForm.teachername='',
      this.getClassNameListAction(1,10)

    },
    submitForm() {
      console.log("chanxun ",this.ruleForm);
    
      var that = this

      if (this.selectClassTypeSearch == "30分钟"){
          this.ruleForm.type =1
        }
        if (this.selectClassTypeSearch == "60分钟"){
          this.ruleForm.type =2
        }
        if (this.selectClassTypeSearch == "体验课"){
          this.ruleForm.type =3
        }
        if( this.ruleForm.companyname==''){
          this.ruleForm.companyname = JSON.parse(sessionStorage.getItem("login"))[0]["companyName"]
        }
      getClassNameList({ 
        companyName: this.ruleForm.companyname,
        page:1,number:10,
        firstDate:this.startTime,
        endDate:this.endTime,
        type:this.ruleForm.type ,
        studentName:this.ruleForm.studentname,
        teacherName:this.ruleForm.teachername,
      })
        .then((response) => {
          that.tableData =response.data,
          that.tableDatalen = response.len
      
        }
    
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });


    },
   // 重置
    resetForm() {
          this.selectClassType ='' ,
          this.selectTypeValue ='' ,
          this.selectTeacherName='' ,
          this.selectTeacherValue='' ,
          this.selectStudentPhone='' ,
          this.selectStudentName='' ,
          this.selectThree='' ,
          this.firstTime='' ,
          this.selectTimeFive='' ,
          this.testTime='' ,
          this.selectFour='' 
          this.ruleForm.teachername=''
          this.ruleForm.studentname=''
          this.ruleForm.companyname=''
          this.startTime=''
          this.endTime=''
          this.stundeName =[]
          this.className=[]

          // this.getClassNameListAction(1,10)
    },
    // 获取学生名
    getStudentMaterial(){
     var companyName = JSON.parse(sessionStorage.getItem("login"))[0]["companyName"]
     var that = this
     getStudentMaterialList({ companyName: companyName })
      .then((response) => 
       
       that.student = response.data

    
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    },
    creatByteacher() {
    this.dialogVisibleteacher = true;
    this.getStudentMaterial()
   
    },
    creatByTime() {
      this.dialogVisible = true;
      this.getStudentMaterial()
    },
  // 修改预约
    handleEdit(index) {
      console.log(this.tableData[index]);
      this.updateManagement=this.tableData[index]
      if(this.tableData[index].type == 1){
        this.selectClassType = "30分钟"
      }
      if(this.tableData[index].type == 2){
        this.selectClassType = "60分钟"
      }
      if(this.tableData[index].type == 0||this.tableData[index].type == 3){
        this.selectClassType = "测试课"
      }
      console.log( this.tableData[index])
      this.selectTypeValue=this.tableData[index].selectType,//交付方式
      this.firstTime = this.tableData[index]["firstDate"],//首次时间
      this.testTime=this.tableData[index]["testDate"],//测试时间
      console.log(this.tableData[index])
      this.selectTeacherValue=this.tableData[index]["teacherPhone"],//教练姓名
      this.selectThree=this.tableData[index]["bookName"],//课程名
      this.selectStudentName = this.tableData[index]["studentName"]
      this.updatestudentphone = this.tableData[index]["studentPhone"]
      this.getStudentMaterial()
      this.updatedialogVisible = true
    },
    handleDelete(index) {
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0]["companyName"]
      deleteClassNameList({ 
        id:this.tableData[index]["id"],
        companyName:companyName
      
       })
      .then((response) => 
      {
        if(response.code == 200){
          this.tableData =response.data
          console.log(response.data)
          this.tableDatalen = response.data
        }
      }
      
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    },
    handleSizeChange() {

      this.getClassNameListAction(this.currentPage4,this.pageSize4)

    },
    handleCurrentChange() {

      this.getClassNameListAction(this.currentPage4,this.pageSize4)
    
    },

    getClassNameListAction(index,number){
      var that = this
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0]["companyName"]
      this.company = companyName
      getClassNameList({
        companyName: this.ruleForm.companyname,
        page:index,
        number:number,
        firstDate:this.startTime,
        endDate:this.endTime,
        type:this.ruleForm.type ,
        studentName:this.ruleForm.studentname,
        teacherName:this.ruleForm.teachername,
       })
        .then((response) => {
          console.log(response.data)
          that.tableData =response.data,
          that.tableDatalen = response.len
      
        }
    
    )
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });

    },
    checkMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      console.log(" isMobile  === ", this.isMobile)
    },
    // 选择课程类型 查找出有权限的学生 查出课程 以及进度 
  },
  created() {
    this.checkMobile()
      for(var i = 0;i<JSON.parse(sessionStorage.getItem("teacher")).length;i++){
        this.selectTeacher.push({
          
          value: JSON.parse(sessionStorage.getItem("teacher"))[i].phone,
          label: JSON.parse(sessionStorage.getItem("teacher"))[i].name,
        
        })
      } 

  

     this.getClassNameListAction(1,10)

    // 
 },
  data() {
    return {
      selectClassNumber:0,
      isMobile: false,
      updatestudentphone:'',
      tableDatalen:[],
      tableData: [ ],
      updatedialogVisible:false,
      updateManagement:[],
      company:"",
      classMoney:0,
      selectFour:"",//预约次数
      selectTimeFive:"1",//间隔时间
      selectClassType: "",//课程类型
      selectClassTypeSearch: "",//课程类型
      selectTypeValue:"",//交付方式
      firstTime: "",//首次时间
      testTime:"",//测试时间
      selectTeacherValue:"",//教练姓名
      selectTeacherName:"",//教练姓名
      selectThree:"",//课程名
      selectStudentName:"",//学生名
      selectStudentPhone:"",//学生电话
      moneyDetail:0,//金额
      endTime:'',
      creatTime:'',//创建事件
      timeValue :'',
      items: [
        // 假设每个项目都有一个时间属性，例如 'time'
        { id: 1, content: 'true', startTime: "2:00",endTime:"1:00" },
        { id: 2, content: 'true', startTime: "3:00",endTime:"5:00" },
        { id: 3, content: 'false', startTime:"6:00",endTime:"9:00" },
        // ... 更多事件
      ],

      student:[],
      selectStudent:[],
      selectDate: "",
      selectDate2: "",
      stundeName : [],
      selectOne:0,
      className:[],
      selectNumber:[
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
        {
          value: "13",
          label: "13",
        },
        {
          value: "14",
          label: "14",
        },
        {
          value: "15",
          label: "15",
        },
        {
          value: "16",
          label: "16",
        },
        {
          value: "17",
          label: "17",
        },
        {
          value: "18",
          label: "18",
        },
        {
          value: "19",
          label: "19",
        },
        {
          value: "20",
          label: "20",
        },
        {
          value: "21",
          label: "21",
        },
        {
          value: "22",
          label: "22",
        },
        {
          value: "23",
          label: "23",
        },
        {
          value: "24",
          label: "24",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "26",
          label: "26",
        },
        {
          value: "27",
          label: "27",
        },
        {
          value: "28",
          label: "28",
        },
        {
          value: "29",
          label: "29",
        },
        {
          value: "30",
          label: "30",
        },
        {
          value: "31",
          label: "31",
        },
        {
          value: "32",
          label: "32",
        },
        {
          value: "33",
          label: "33",
        },
        {
          value: "34",
          label: "34",
        },
        {
          value: "35",
          label: "35",
        },
        {
          value: "36",
          label: "36",
        },
        {
          value: "37",
          label: "37",
        },
        {
          value: "38",
          label: "38",
        },
        {
          value: "39",
          label: "39",
        },
        {
          value: "40",
          label: "40",
        },
        {
          value: "41",
          label: "41",
        },
        {
          value: "42",
          label: "42",
        },
        {
          value: "43",
          label: "43",
        },
        {
          value: "44",
          label: "44",
        },
        {
          value: "45",
          label: "45",
        },
        {
          value: "46",
          label: "46",
        },
        {
          value: "47",
          label: "47",
        },
        {
          value: "48",
          label: "48",
        },
        {
          value: "49",
          label: "49",
        },
        {
          value: "50",
          label: "50",
        },
        {
          value: "51",
          label: "51",
        },
        {
          value: "52",
          label: "52",
        },
        {
          value: "53",
          label: "53",
        },
        {
          value: "54",
          label: "54",
        },
        {
          value: "55",
          label: "55",
        },
        {
          value: "56",
          label: "56",
        },
        {
          value: "57",
          label: "57",
        },
        {
          value: "58",
          label: "58",
        },
        {
          value: "59",
          label: "59",
        },
        {
          value: "60",
          label: "60",
        }
      ],
      selectTimeNumber:[
      {
            value: "1",
            label: "每天",
          },
          {
            value: "2",
            label: "间隔1天",
          },
          {
            value: "3",
            label: "间隔2天",
          },
          {
            value: "4",
            label: "间隔3天",
          },
          {
            value: "5",
            label: "间隔4天",
          },
          {
            value: "6",
            label: "间隔5天",
          },
          {
            value: "7",
            label: "间隔1周",
          },
          {
            value: "8",
            label: "同一天（间隔30分钟）",
          },
          {
            value: "9",
            label: "同一天（间隔0分钟）",
          }
      ],
      selectTeacher:[],
      date: "",
      dialogVisibleteacher: false,
   
      selectClassValue: "",
      startTime: "",
      currentPage4: 1,
      pageSize4: 10,
      dialogVisible: false,
      title: "",
      message: "",
      ruleForm: {
        companyname: "",
        studentname: "",
        teachername: "",
        type: ""
      },
      classTypeSearch:[
        {
          value: "1",
          label: "30分钟",
        },
        {
          value: "2",
          label: "60分钟",
        },
        {
          value: "3",
          label: "体验课",
        }
      ],
      classType: [
        {
          value: "1",
          label: "30分钟",
        },
        {
          value: "2",
          label: "60分钟",
        },
        {
          value: "3",
          label: "体验课",
        }
      ],
      selectType: [
        {
          value: "1",
          label: "线上",
        },
        {
          value: "2",
          label: "上门",
        },
        {
          value: "3",
          label: "机构",
        }
      ],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      TeccherTableData: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "详情",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "详情",
        },
        {
          date: "2016-05-04",
          name: "Tom",
          address: "详情",
        },
        {
          date: "2016-05-01",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
   height:36vw;
  overflow: hidden;
}

.colorBackgroundMobile{
  background-color: white;
  height:55vw
}
.colorBackgroundPC{
  background-color: white;
  height:38vw
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 10px;

}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  /* position: fixed;
  bottom: 0.1vw; */
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 30vw;
}
.custom-style .el-segmented {
  --el-segmented-item-selected-color: var(--el-text-color-primary);
  --el-segmented-item-selected-bg-color: #ffd100;
  --el-border-radius-base: 16px;
  background-color: rgb(0, 217, 255);
  width: 300px;
  color: #000;
}
</style>
    