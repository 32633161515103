<template>
  <div class="colorBackground">
    
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item label="" prop="title">
        <el-date-picker
          v-model="startTime"
          type="month"
          placeholder="开始日期"
          format="YYYY-M"
          value-format="YYYY-M"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button  class="header"  type="primary" @click="submitForm">
          查询
        </el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
    <div style="color: red;margin-bottom: 20px;font-size: large;">合计佣金：{{allmoney}} 元  30分钟单价：40，60分钟单价80，体验课单价：200</div>
    <el-table :data="tableData" style="width: 100%;height: 27vw;overflow-y: auto;"   @cell-click="handleClick">
      <el-table-column fixed width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
       
      <el-table-column property="studentphone" label="用户账号"    show-overflow-tooltip />
      <el-table-column property="studentname" label="用户姓名"    show-overflow-tooltip />
      <el-table-column property="three" label="30分钟课程"    show-overflow-tooltip />
      <el-table-column property="six" label="60分钟课程"    show-overflow-tooltip />
      <el-table-column property="trial" label="体验课"    show-overflow-tooltip />
      <el-table-column property="all" label="总课数"    show-overflow-tooltip />
      <el-table-column property="finishmoney" label="佣金"    show-overflow-tooltip />
    </el-table>
   
    <el-pagination
      v-model:current-page="currentPage4"
      v-model:page-size="pageSize4"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      style="background-color: white;height: 45px;padding-left: 10px;"
    />

    <el-dialog v-model="dialogVisible" :title="title" width="500">
      <span>{{ message }}</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">
            已读
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
   
    <script>
//  import { reactive, ref } from 'vue'

import { getTeacherMoney } from "../../utils/api";
export default {
  name: "MONEY",
  methods: {
    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);
      this.getTeacherMoney(this.pageSize4,this.currentPage4);
    },
    handleCurrentChange() {
      console.log(`current page:`, this.pageSize4, this.currentPage4);
      this.getTeacherMoney(this.pageSize4,this.currentPage4);
    },
    submitForm() {

      getTeacherMoney({
        companyName:this.companyName,
        finishDate:this.startTime,
        number:10,
        page:1
      })
      .then((response) => {
          if (response.code !=400){
            this.allmoney = response.allmoney
            this.tableData = response.studentList
            this.count = response.count
            //  sessionStorage.setItem("money", JSON.stringify(response.data) )
             console.log(response)
          }
        }
      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })

    },

    resetForm() {
      let now = new Date(),
       year = now.getFullYear(), //得到年份
       month = now.getMonth() + 1
      this.startTime = year +  "-" + month
      this.getTeacherMoney(10,1)
    },
    getTeacherMoney(number,page){
      // var that = this
      let now = new Date(),
       year = now.getFullYear(), //得到年份
       month = now.getMonth() + 1
 
      this.companyName =  JSON.parse(sessionStorage.getItem("login"))[0][
        "companyName"
      ]
      console.log(this.companyName,year +  "-" + month,number,page)
      getTeacherMoney({
        companyName:this.companyName,
        finishDate:this.startTime ,
        number:number,
        page:page
      })
      .then((response) => {
          if (response.code !=400){
            this.allmoney = response.allmoney
            this.tableData = response.studentList
            this.count = response.count
            //  sessionStorage.setItem("money", JSON.stringify(response.data) )
             console.log("this.count ==== ",this.count )
          }
        }
         
        // 
        // 

      )
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        })

    },
    handleClick(row){
      this.dialogVisible = true
      this.message = row.message
      this.title = row.title
      console.log(row.message)
    }
    },
  data() {
    return {
      count:0,
      allmoney:'',
      studentList:[],
      startTime:'',
      currentPage4:1,
      pageSize4:10,
      companyName:'',
      dialogVisible: false,
      title: "",
      message: "",
      ruleForm: {
        title: "",
        message: "",
      },
      tableData: [
       
      ],
    };
  },
  created(){
    this.resetForm(10,1)
  }
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
.colorBackground {
   height:38vw
}
.el-row {
  position: relative;
  margin-left:10px;
  padding-top:5px;

}
.header{

  margin-left:10px;

}
.paginationClass {
    position: fixed;
    bottom: 0px;
    height: 40px;
    width: 100%;
    text-align: center;
    margin-left: 30%;
}
</style>
    