import request from '@/utils/request';
 
// 获取用户列表
export function getUserList(params) {
  return request({
    url: '/management/student',
    method: 'post',
    params
  });
}
export function getCompany(params) {
  return request({
    url: 'management/companyLogin',
    method: 'post',
    params
  });
}
 
export function getRecord(params) {
  return request({
    url: 'management/searchReachargeRecord',
    method: 'post',
    params
  });
}
 
export function getNotice(params) {
  return request({
    url: 'management/getNoticeList',
    method: 'get',
    params
  });
}
 
export function getStudentMaterialList(params) {
  return request({
    url: 'management/studentmateriallist',
    method: 'post',
    params
  });
}

export function getbookall(params) {
  return request({
    url: 'management/bookall',
    method: 'post',
    params
  });
}

export function insertClassNameList(params) {
  return request({
    url: 'management/insertClassNameList',
    method: 'post',
    params
  });
}

export function getClassNameList(params) {
  return request({
    url: 'management/getClassNameList',
    method: 'post',
    params
  });
}

export function getCompanyAllStudent(params) {
  return request({
    url: 'management/getCompanyAllStudent',
    method: 'post',
    params
  });
}

export function insertReachargeRecord(params) {
  return request({
    url: 'management/insertReachargeRecord',
    method: 'post',
    params
  });
}


export function getReachargeRecord(params) {
  return request({
    url: 'management/getReachargeRecord',
    method: 'post',
    params
  });
}

export function searchFinishClassList(params) {
  return request({
    url: 'management/searchFinishClassList',
    method: 'post',
    params
  });
}

export function searchFinishiTestList(params) {
  return request({
    url: 'management/searchFinishiTestList',
    method: 'post',
    params
  });
}

export function classOpenDetail(params) {
  return request({
    url: 'management/classOpenDetail',
    method: 'post',
    params
  });
}

export function getAllBookName(params) {
  return request({
    url: 'management/searchAllBookName',
    method: 'post',
    params
  });
}


export function searchNotice(params) {
  return request({
    url: 'management/searchNotice',
    method: 'post',
    params
  });
}

export function updateClassNameList(params) {
  return request({
    url: 'management/updateClassNameList',
    method: 'post',
    params
  });
}

export function deleteClassNameList(params) {
  return request({
    url: 'management/deleteClassNameList',
    method: 'post',
    params
  });
}

export function getTeacherFinishList(params) {
  return request({
    url: 'management/insertEvaluationList',
    method: 'post',
    params
  });
}

export function insertIntoStudent(params) {
  return request({
    url: 'management/insetIntoStudent',
    method: 'post',
    params
  });
}


export function getWordTestNumber(params) {
  return request({
    url: 'management/getWordTestNumber',
    method: 'post',
    params
  });
}

export function updatePassWord(params) {
  return request({
    url: 'management/updatePassWord',
    method: 'post',
    params
  });
}


export function updateStudent(params) {
  return request({
    url: 'management/updateStudent',
    method: 'post',
    params
  });
}

export function searchCompanyList(params) {
  return request({
    url: 'management/searchCompanyList',
    method: 'post',
    params
  });
}


export function getEvaluationList(params) {
  return request({
    url: 'management/getEvaluationList',
    method: 'post',
    params
  });
}
export function companyReachargeList(params) {
  return request({
    url: 'management/companyReachargeList',
    method: 'post',
    params
  });
}

export function insertcompanyReachargeList(params) {
  return request({
    url: 'management/insertcompanyReachargeList',
    method: 'post',
    params
  });
}


export function searchTeacherList(params) {
  return request({
    url: 'management/searchTeacherList',
    method: 'post',
    params
  });
}


export function insertIntoTeacher(params) {
  return request({
    url: 'management/insetIntoTeacher',
    method: 'post',
    params
  });
}


export function insertIntoCompany(params) {
  return request({
    url: 'management/insetIntoCompany',
    method: 'post',
    params
  });
}

export function getVersion(params) {
  return request({
    url: 'management/getVersion',
    method: 'get',
    params
  });
}
export function getTeacherMoney(params) {
  return request({
    url: 'management/getTeacherMoney',
    method: 'post',
    params
  });
}


export function getwordtestnumberList(params) {
  return request({
    url: 'management/getwordtestnumberList',
    method: 'post',
    params
  });
}

export function companyReturnList(params) {
  return request({
    url: 'management/companyReturnList',
    method: 'post',
    params
  });
}


export function insertIntoReturn(params) {
  return request({
    url: 'management/insertIntoReturn',
    method: 'post',
    params
  });
}

export function searchCompanyReturnList(params) {
  return request({
    url: 'management/searchCompanyReturnList',
    method: 'post',
    params
  });
}

export function updatecompanyReturn(params) {
  return request({
    url: 'management/updatecompanyReturn',
    method: 'post',
    params
  });
}


export function myview(params) {
  return request({
    url: 'management/myview',
    method: 'get',
    params
  });
}


export function getCompanyDetail(params) {
  return request({
    url: 'management/getCompanyDetail',
    method: 'post',
    params
  });
}


export function getwordtestnumberdetail(params) {
  return request({
    url: 'management/getwordtestnumberdetail',
    method: 'post',
    params
  });
}


export function updateCompanyThreeClass(params) {
  return request({
    url: 'management/updateCompanyThreeClass',
    method: 'post',
    params
  });
}


export function gethistoryListDeatailclass(params) {
  return request({
    url: 'management/gethistoryListDeatailclass',
    method: 'post',
    params
  });
}


export function getstudentAllBookName(params) {
  return request({
    url: 'management/getAllBookName',
    method: 'post',
    params
  });
}

export function removeStudentClass(params) {
  return request({
    url: 'management/removeStudentClass',
    method: 'post',
    params
  });
}

export function updatestudenthistory(params) {
  return request({
    url: 'management/updatestudenthistory',
    method: 'post',
    params
  });
}

export function student(params) {
  return request({
    url: 'management/student',
    method: 'post',
    params
  });
}

export function getUserImage(params) {
  return request({
    url: 'management/getUserImage',
    method: 'post',
    params
  });
}

export function getReadingGradeList(params) {
  return request({
    url: 'management/getReadingGradeList',
    method: 'post',
    params
  });
}
export function getTeacherGetReadingList(params) {
  return request({
    url: 'management/teacherGetReadingList',
    method: 'post',
    params
  });
}

export function readingOpenList(params) {
  return request({
    url: 'management/readingOpenList',
    method: 'post',
    params
  });
}

export function removeStudentReadingList(params) {
  return request({
    url: 'management/removeStudentReadingList',
    method: 'post',
    params
  });
}


export function openReadingClass(params) {
  return request({
    url: 'management/openRedingClass',
    method: 'post',
    params
  });
}


export function getStudentClassList(params) {
  return request({
    url: 'management/getStudentClassList',
    method: 'post',
    params
  });
}

export function updateReadClassList(params) {
  return request({
    url: 'management/updateReadClassList',
    method: 'post',
    params
  });
}