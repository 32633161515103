 
import axios from 'axios';
import { ElLoading } from 'element-plus'

let loadingRequestCount = 0
let loadingInstance
const showLoading = () => {
  if (loadingRequestCount === 0) {
    loadingInstance = ElLoading.service({ target: '#app' })
  }
  loadingRequestCount += 1
}
const hideLoading = () => {
  if (loadingRequestCount <= 0) return
  loadingRequestCount -= 1
  if (loadingRequestCount === 0) {

    loadingInstance.close()
    // })
  }
}

// 创建axios实例
const service = axios.create({
 // baseURL: 'https://ajyyxlxt.com', // api的base_url 生产//
  baseURL: 'http://test-mgr.ajyyxlxt.com', // api的base_url 测试
  //  baseURL: 'http://192.168.3.3:8000', // api的base_url  本地
  timeout: 50000000 // 请求超时时间
});
 
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 可以在这里添加请求头等信息
    showLoading()
    return config;
  },
  error => {
    // 请求错误处理
    console.log(error); // for debug
    Promise.reject(error);
  }
);
 
// 响应拦截器
service.interceptors.response.use(
  response => {
    // 可以在这里对响应数据进行处理
    hideLoading()
    const res = response.data;
    // 根据返回的状态码做相应处理，例如401未授权等
    return res;
  },
  error => {
    // 响应错误处理
    hideLoading()
    console.log('err' + error); // for debug
    return Promise.reject(error);
  }
);
 
export default service;