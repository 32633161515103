<template>
  <div class="colorBackground">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="companyname">
        <el-input
          placeholder="请输入完整机构名称"
          v-model="ruleForm.companyname"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="teachername">
        <el-input
          placeholder="请输入完整陪练姓名"
          v-model="ruleForm.teachername"
          autocomplete="teachername"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="studentname">
        <el-input
          placeholder="请输入完整用户姓名"
          v-model="ruleForm.studentname"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="classtype">
        <el-select
          v-model="selectClassType"
          clearable
          placeholder="请选择课程类型"
          style="width: 240px"
          @change="handleChange"
        >
          <el-option
            v-for="item in classType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="inputclass" prop="startTime">
        <el-date-picker
          v-model="startTime"
          type="date"
          placeholder="开始日期"
          format="YYYY/M/DD"
          value-format="YYYY-M-DD"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item class="inputclass" prop="endTime">
        <el-date-picker
          v-model="endTime"
          type="date"
          placeholder="结束日期"
          format="YYYY/M/DD"
          value-format="YYYY-M-DD"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm(1,10)">
          查询 
        </el-button>
        <el-button @click="resetForm(10, 1)">重置</el-button>
      </el-form-item>
    </el-form>


    <el-table :data="tableData" style="width: 100%; height: 26vw">
      <!-- <el-table-column type="selection" width="55" /> -->

      <el-table-column fixed width="100" label="序号">
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column width="150" property="sName" label="用户" />
      <el-table-column width="150" label="复习方式">
        <template #default="">1V1</template>
      </el-table-column>
      <el-table-column width="150" label="操作">
        <template #default="scope">
          <!-- 操作菜单 -->
          <el-dropdown size="small" trigger="click" split-button type="primary">
            操作

            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="b" @click="detail(scope.$index)"
                  >详情</el-dropdown-item
                >
                <el-dropdown-item
                  command="c"
                  @click="printPreview(scope.$index)"
                  >打印</el-dropdown-item
                >
                <el-dropdown-item
                  command="c"
                  @click="gethistoty(scope.$index)"
                  >抗遗忘</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>

      <el-table-column width="150" property="Tname" label="陪练" />
      <el-table-column width="150" property="histotyName" label="抗遗忘教练" />
      <el-table-column width="150" property="bookName" label="资料名" />
      <!-- <el-table-column  width="150" label="本次扣除陪练时长"  >
        <template #default="scope">{{ scope.row.type =1?"0.5":"1" }}</template>
      </el-table-column> -->
      <el-table-column width="300" label="本次扣除陪练时长">
        <template #default="scope">{{
          scope.row.type == '30分钟' ? "0.5" : "1"
        }}</template>
      </el-table-column>
      <el-table-column width="300" property="starTime" label="开始时间" />
      <el-table-column width="300" property="finishDate" label="结束时间" />
      <el-table-column width="300" label="总数">
        <template #default="scope">{{
          
          JSON.parse(scope.row.haveLearn).length
        }}</template>
      </el-table-column>
      <el-table-column width="300" property="companyName" label="交付中心" />
      <el-table-column width="300" property="date" label="时长类型">
        <template #default="scope">{{
          scope.row.type
        }}</template>
      </el-table-column>
      <el-table-column width="300" label="交付方式">
        <template #default="">线上</template>
      </el-table-column>
      <el-table-column width="300" property="date" label="体验结果" />

      <el-table-column width="300" property="classID" label="编号" />
    </el-table>

   
      <el-pagination
        style="height: 45px;padding-left: 10px;"
        v-model:current-page="currentPage4"
        v-model:page-size="pageSize4"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
   
    <el-dialog
      v-model="centerDialogVisible"
      title="练习情况"
      width="700px"
      center
    >
      <div>
        <div>机构名称：{{ company }}</div>
        <div class="dialogclass">
          <div>用户姓名:{{ detailMessage.sName }}</div>
          <div>陪练姓名:{{ detailMessage.Tname }}</div>
        </div>

        <div class="dialogclass">
          <div>课程名称:{{ detailMessage.bookName }}</div>
          <div>总单词数:{{JSON.parse(detailMessage.haveLearn).length+detailMessage.noHaveLearn?JSON.parse(detailMessage.haveLearn).length:0}}</div>
        </div>

        <div class="dialogclass">
          <div>正确数：{{JSON.parse(detailMessage.haveLearn).length}}</div>
          <div>错误数：{{detailMessage.noHaveLearn?JSON.parse(detailMessage.haveLearn).length:'0'}}</div>
        </div>

        <div class="dialogclass">
          <div>开始时间：{{ detailMessage.starTime }}</div>
          <div>结束时间：{{ detailMessage.finishDate }}</div>
        </div>
        <div style="margin-top: 20px;margin-bottom: 20px">用户评价 : {{this.evaluationDetail.length!=0?this.evaluationDetail["studentevalutionDeatil"]:''}}</div>
        <div>教练评价 : {{this.evaluationDetail.length!=0?this.evaluationDetail["teacherevalutionDeatil"]:''}}</div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="centerDialogVisible = false">
            关闭
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="historyDialogVisible"
      title="更换遗忘教练"
      width="700px"
      center
    >
      <div   >
         <el-select
                 v-model="selectTeacherValue"
                 clearable
                 placeholder="更换遗忘教练"
                 style="width: 240px;margin-left: 10vw;"
                 @change="selectHistory"
               >
                <el-option
                  v-for="item in selectHistoryTeacher"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value"
                />
        </el-select>
        <el-button style="margin-left: 50px;" type="success" @click="updateHistoryTeacher" >确认</el-button>
        </div>
      </el-dialog>
  </div>
</template>
   
    <script>
//  import { reactive, ref } from 'vue'
// import { cosh } from "core-js/core/number";updateStudentHistory
import { searchFinishClassList,getEvaluationList,updatestudenthistory } from "../../utils/api";
export default {
  name: "TESTLIST",
  methods: {
    
    updateHistoryTeacher(){
      this.historyDialogVisible = false
        updatestudenthistory({
        studentphone: this.beforphone,
        teacherphone: this.afterphone
      })
        .then((response) => {
          console.log(response),
         
          location.reload()
        
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
      
      
    },
    selectHistory(value){

      this.afterphone = value
    },
    gethistoty(value){
      
      // this.historyDialogVisible = true 
      // // histotyPhone
      // console.log(this.tableData[value].histotyName)
      // this.selectHistoryTeacher = this.tableData[value]
      this.beforphone =  this.tableData[value].studentName
      this.selectTeacherValue =  this.tableData[value].histotyName
      this.historyDialogVisible = true
      this.selectHistoryTeacher = []
      for(var i = 0;i<JSON.parse(sessionStorage.getItem("teacher")).length;i++){
        
        this.selectHistoryTeacher.push({
          
          value: JSON.parse(sessionStorage.getItem("teacher"))[i].phone,
          label: JSON.parse(sessionStorage.getItem("teacher"))[i].name,
        
        })
      } 
      
      
    },

    detail(index) {
     
      this.detailMessage = this.tableData[index];
      this.evaluationDetail = []
      // // var that = this
      getEvaluationList({
        classID: this.tableData[index].id,
      })
        .then((response) => {
          console.log(response)
          //  this.evaluationState = response.state
          
          if( response.data.length!=0){
            this.evaluationDetail = response.data[0]
          }
           
          
          this.centerDialogVisible = true;
        
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    printPreview(index) {

      // console.log(this.tableData[index],process.env)
      window.open('https://ajyyxlxt.com/management/myview?classID='+this.tableData[index]["classID"],'_blank');


    },

    handleChange(value) {
      console.log('=-=====  ',value);
    },
    deleteRow(index) {
      console.log(index);
    },

    submitForm(page,number) {
      console.log(this.ruleForm.classtype);
      this.pageSize4 =  number
      this.currentPage4 = page
      var that = this;
      var compnayNameSearche = "";
      if (this.ruleForm.companyname == "") {
        compnayNameSearche = JSON.parse(sessionStorage.getItem("login"))[0][
          "companyName"
        ];
      } else {
        compnayNameSearche = this.ruleForm.companyname;
      }
      searchFinishClassList({
        number: number,
        page: page,
        Tname: this.ruleForm.teachername,
        sName: this.ruleForm.studentname,
        startTime: this.startTime,
        finishTime: this.endTime,
        type: this.selectClassType,
        companyName: compnayNameSearche,
      })
        .then((response) => {
          that.tableData = response.data;
          this.count = response.count
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    resetForm(number, page) {
      this.ruleForm = {
        companyname: "",
        studentname: "",
        teachername: "",
        classtype: "",
      };
      this.startTime='',
      this.endTime=''
      this.selectClassType = "";
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
        "companyName"
      ];
      var that = this;
      searchFinishClassList({
        Tname: "",
        Sname: "",
        startTime: "",
        finishTime: "",
        type: "",
        companyName: companyName,
        number: number,
        page: page,
      })
        .then((response) => {
          (that.tableData = response.data),
          this.count = response.count
           console.log(response);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },

    handleEdit(index) {
      console.log(index);
    },
    handleDelete(index) {
      console.log(index);
    },
    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);
      this.submitForm( this.currentPage4,this.pageSize4);
    },
    handleCurrentChange() {
      console.log(`current page:`, this.pageSize4, this.currentPage4);
      this.submitForm( this.currentPage4,this.pageSize4);
    },
  },
  created() {
    // django 接口
    this.resetForm(10, 1);
  },
  data() {
    return {
      beforphone:'',
      afterphone:'',
      selectTeacherValue:'',
      selectHistoryTeacher:[],
      historyDialogVisible:false,
      count:0,
      evaluationDetail:[],
      evaluationState:0,
      detailMessage: [],
      selectClassType: "",
      centerDialogVisible: false,
      classType: [
        {
          value: "1",
          label: "30分钟",
        },
        {
          value: "2",
          label: "60分钟",
        },
        {
          value: "3",
          label: "体验课",
        },
      ],
      Timetype: "",

      selectDate: "",
      selectDate2: "",

      date: "",
      selectClassValue: "",
      startTime: "",
      endTime: "",
      currentPage4: 1,
      pageSize4: 10,

      title: "",
      message: "",
      ruleForm: {
        companyname: "",
        studentname: "",
        teachername: "",
        classtype: "",
      },
      tableData: [],
      company: JSON.parse(sessionStorage.getItem("login"))[0]["companyName"],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
  height:38vw;
  overflow: hidden;
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 10px;
}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 30%;
}
.dialogclass {
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 20px;
}
</style>
    