<template>
  <div class="colorBackground">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="auto"
      class="el-row"
    >
      <el-form-item class="inputclass" prop="code">
        <el-input
          placeholder="编码"
          v-model="ruleForm.code"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="inputclass" prop="name">
        <el-input
          placeholder="机构名称"
          v-model="ruleForm.name"
          autocomplete="off"
        />
      </el-form-item>
    

      <el-form-item class="inputclass">
        <el-button type="primary" @click="submitForm(10,1)">
          查询
        </el-button>
        <el-button @click="resetForm(ruleFormRef)">重置</el-button>
      </el-form-item>
    </el-form>
 
  <el-table :data="tableData" style="width: 100%; height: 28vw">
    <!-- <el-table-column type="selection" width="55" /> -->

    <el-table-column fixed prop="date" width="100" label="序号">
      <template #default="scope">{{ scope.$index + 1 }}</template>
    </el-table-column>
    <el-table-column width="150" property="companyCode" label="编码" />
    <el-table-column width="150" property="companyUserName" label="别名" />
    <el-table-column width="140" label="操作">
      <template #default="scope" >
        <!-- 操作菜单 -->
        <el-dropdown
          v-show="scope.row.state==1?true:false" 
          size="small"
          trigger="click"
          split-button
          type="primary"
        
        >
          操作

          <template #dropdown>
            <el-dropdown-menu    >
              <el-dropdown-item
                command="b"
                @click="reachaegeDetail(scope.$index)"
                >充值明细</el-dropdown-item
              >
              <el-dropdown-item
                 v-if="this.tableData[scope.$index].companyUserName == this.company? false:true"
                 command="c" 
                 @click="reachaege(scope.$index)"
                >充值</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>

       
         <el-button    size="small" type="warning"   v-show="scope.row.state==1?false:true" >待审核</el-button>
 
      </template>
     
    </el-table-column>
    <el-table-column width="150" property="companyName" label="实名" />
    <el-table-column
      width="150"
      property="payCenterName"
      label="交付中心"
      show-overflow-tooltip
    />
    <el-table-column width="150" property="userName" label="负责人" />
    <el-table-column width="150" property="userPhone" label="负责人电话" />
    <el-table-column width="150" property="people" label="推荐人" />
    <el-table-column width="150" property="type" label="陪练收取方式" />
    <el-table-column width="150" property="totalTime" label="陪练时长" />
    <el-table-column width="150" property="sixTime" label="60分钟软件时长" />
    <el-table-column width="150" property="threeTime" label="30分钟软件时长" />
    <el-table-column
      width="150"
      property="trialClassTime"
      label="软件体验时长"
    />
    <el-table-column
      width="150"
      property="accumulativesix"
      label="累计60分钟"
    />
    <el-table-column
      width="150"
      property="accumulativethree"
      label="累计30分钟"
    />
    <el-table-column width="150" property="state" label="状态" >
      <template #default="scope">{{ scope.row.state ==1?"审核通过":"待审核" }}</template>

    </el-table-column>
    <el-table-column
      width="150"
      property="contractDeadline"
      label="合同到期日"
    />
    <el-table-column width="150" property="creatTime" label="创建日期" />
  </el-table>

    <el-pagination
      v-model:current-page="currentPage4"
      v-model:page-size="pageSize4"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      style="margin-left: 20px;height: 45px;;"
    />
  </div>
  <!-- 充值 -->
  <el-dialog v-model="dialogVisibleRecharge" title="充值" width="800px">
    <span>机构名称 ：{{ title }}</span>
    <div class="rechargeClass">
      充值类型:
      <el-radio-group
        v-model="radio"
        @change="radioChanege"
        style="margin-left: 10px"
      >
        <!-- <el-radio :value="1" border>30分钟</el-radio> -->
        <el-radio :value="2" border>60分钟</el-radio>
        <el-radio :value="3" border>体验</el-radio>
        <el-radio :value="4" border>陪练</el-radio>
      </el-radio-group>
    </div>
    <div class="rechargeInput">
      <div>充值数量 :</div>

      <el-input
        v-model="input2"
        style="max-width: 300px; margin-left: 10px"
        maxlength="3"
        @input="handleInput"
        placeholder="请输入1-200之间的整数"
      >
        <template #append>节</template>
      </el-input>
      <div style="color: red; margin-left: 10px">
        大写 : {{ input2Chinsese }}
      </div>
    </div>
    <div>
      <div class="rechargeInput" style="margin-top: 20px">
        <div>充值金额 :</div>

        <el-input
          v-model="input3"
          style="max-width: 300px; margin-left: 10px"
          @input="handleInput2"
          placeholder="请输入1-200之间的整数"
        >
          <template #append>节</template>
        </el-input>
        <div style="color: red; margin-left: 10px">
          大写 : {{ input3Chinsese }}
        </div>
      </div>
    </div>

    <div style="margin-top: 10px">
      付款方式:
      <el-select
        v-model="SelectValue"
        placeholder="请选择"
        size="default"
        style="width: 240px; margin-left: 10px"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.label"
        />
      </el-select>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <el-button>重置</el-button>
        <el-button type="primary" @click="submitReachage"> 提交 </el-button>
      </div>
    </template>
  </el-dialog>
  <!-- 充值明细 -->
  <el-dialog v-model="dialogTableVisible" title="充值明细" width="800"  style="height: 65vh">
    <el-table :data="gridData" style="height: 44vh;">
      <el-table-column fixed  width="100" label="序号">
      <template #default="scope">{{ scope.$index + 1 }}</template>
    </el-table-column>
      <el-table-column property="rechargeCompanyName" label="充值机构" width="200" />
      <el-table-column property="companyThreeBefore" label="30充值前"  width="200" />
      <el-table-column property="companyThreeAfter" label="30充值后" width="250" />
      <el-table-column property="companySixBefore" label="60充值前" width="200" />
      <el-table-column property="companySixAfter" label="60充值后" width="200" />
      <el-table-column property="ToCompanyReachargeName" label="被冲机构" />
      <el-table-column property="ToCompanyReachargeUser" label="被冲机构管理" width="200" />
      <el-table-column property="ToCompanyThreeBefore" label="30充值前" width="200" />
      <el-table-column property="ToCompanyThreeAfter" label="30充值后" width="200" />
      <el-table-column property="ToCompanySixBefore" label="60充值前" width="200"/>
      <el-table-column property="ToCompanySixAfter" label="60充值后" width="200" />
      <el-table-column property="allMoney" label="金额"  width="200" />
      <el-table-column property="type" label="付款方式" width="200" />
      <el-table-column property="SellOrBuy" label="是否退费" width="200" >

        <template #default="scope">{{ scope.row.SellOrBuy==0?'充值':'退费' }}</template>

      </el-table-column>
      <el-table-column property="creatPeople" label="操作者" width="200" />
      <el-table-column property="creatTime" label="充值时间" width="200" />

    </el-table>
    <div style="margin-top: 10px" >
    <el-pagination
      v-model:current-page="currentPage1"
      v-model:page-size="pageSize1"
      layout="total, sizes, prev, pager, next, jumper"
      :total="numberReachage"
      @size-change="handleSizeChangeReacharge"
      @current-change="handleCurrentChangeReacharge"
    />
  </div>
  </el-dialog>

</template>
   
    <script>
import {
  searchCompanyList,
  companyReachargeList,
  insertcompanyReachargeList,
} from "../../utils/api";

export default {
  name: "COMPANY",
  methods: {

  
    submitForm(number,page){

      console.log(this.ruleForm)
     var params = { }
     this.company = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyUserName"
    ];
     var upcompanyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyCode"
    ];

    if (this.ruleForm.name==''){
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      params = {
      companyName: companyName,
      companyCode:this.ruleForm.code,
      upCompanyName: upcompanyName,
      number:number,
      page:page}
    }else{

      params = {
      companyName: this.ruleForm.name,
      companyCode:this.ruleForm.code,
      upCompanyName: '',
      number:number,
      page:page,
    }
     
    }
  

    searchCompanyList(params)
      .then((response) => {
        console.log(response.data)
        if(this.ruleForm.code!=''||this.ruleForm.name!=''){
          this.tableData =[]
        for (var i =0;i<response.data.length;i++){
           if(this.ruleForm.code == response.data[i].companyCode){
           
             this.tableData.push(response.data[i])
           }
           if(this.ruleForm.name == response.data[i].companyName){
           
             this.tableData.push(response.data[i])
           }
          }
        }else{
          this.tableData = response.data;
        }
       
       
        console.log(" this.tableData ==", this.tableData[0].companyUserName,this.company);
      })
      .catch(function (error) {
        // 请求失败处理
        console.log(error);
      });
    },


    submitReachage() {
      var companyName = JSON.parse(sessionStorage.getItem("login"))[0][
      "companyName"
    ];
      console.log(
        "======",
        JSON.parse(sessionStorage.getItem("login"))[0]["adminName"]
      );
      let now = new Date(),
        year = now.getFullYear(), //得到年份
        month = ("0" + (now.getMonth() + 1)).slice(-2), //得到月份
        date = now.getDate(); //得到日期
      var creatTime = year + "-" + month + "-" + date;
      var adminName = JSON.parse(sessionStorage.getItem("login"))[0][
        "adminName"
      ];
      var params = {
        rechargeCompanyName: companyName,
        ToCompanyReachargeName: this.title,
        rechargeNumber: this.input2,
        rechargeType: this.SelectValue,
        allMoney: this.input3,
        type: this.radio,
        SellOrBuy: 0,
        creatPeople: adminName,
        creatTime: creatTime,
      };
      insertcompanyReachargeList(params)
        .then((response) => {
          (this.dialogVisibleRecharge = false), 
          this.resetForm();
          console.log(response);
        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
          this.dialogVisibleRecharge = false
        });
    },

    radioChanege(index) {
      console.log(index);
    },
    handleInput2(value) {
      // 正则表达式判断正整数
      const pattern = /^[1-9]\d*$/g;

      if (pattern.test(value)) {
        // 双向绑定
        this.input3 = value;
      } else {
        // 输入值不为正整数，则从中提取出数字并拼接为字符串，赋值给model.time
        const num = value.match(/\d+/g);
        this.input3 = num ? num.join("") : "";
      }
      this.input3Chinsese = this.toChies(this.input3);
      console.log(this.toChies(this.input2));
    },
    handleInput(value) {
      // 正则表达式判断正整数
      const pattern = /^[1-9]\d*$/g;

      if (pattern.test(value)) {
        // 双向绑定
        this.input2 = value;
      } else {
        // 输入值不为正整数，则从中提取出数字并拼接为字符串，赋值给model.time
        const num = value.match(/\d+/g);
        this.input2 = num ? num.join("") : "";
      }
      this.input2Chinsese = this.toChies(this.input2);
      console.log(this.toChies(this.input2));
    },

    toChies(values) {
      //形参
      let len = values.length; //统计出长度
      let arr = [];

      let chin_list = [
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖",
      ]; //所有的数值对应的汉字

      let chin_lisp = [
        "仟",
        "佰",
        "拾",
        "亿",
        "仟",
        "佰",
        "拾",
        "万",
        "仟",
        "佰",
        "拾",
      ]; //进制

      for (let i = 0; i < len; i++) {
        arr.push(parseInt(values[i])); //输入的数据按下标存进去   存进去的只是数字
        arr[i] = chin_list[arr[i]]; //是根据我们输入的输入的数字，对应着我们的chin_list这个数组
      } //123['壹','佰','贰','拾','叁']

      for (let i = len - 1, j = 1; i > 0; i--) {
        //i =2  1       //倒序        为了添加进制，方便我们去观看
        arr.splice(i, 0, chin_lisp[chin_lisp.length - j++]); //j=2
      }
      console.log(arr);

      arr = arr.join("");
      if (len >= 1) {
        arr += "元";
      }
      return arr;
    },

    reachaege(index) {
      this.dialogVisibleRecharge = true;
      this.title = this.tableData[index].companyName;
    },
    reachaegeDetail(index) {
      this.dialogTableVisible = true
      this.reachagecompanyName = this.tableData[index].companyName
      this.getData(this.tableData[index].companyName,10,1)
    },
    getData(value,number,page){
 
      companyReachargeList({
        rechargeCompanyName: this.company,
        ToCompanyReachargeName:value,
        number: number,
        page: page,
      })
        .then((response) => {
          this.gridData = response.data,
          this.numberReachage = response.count
          console.log( response)

        })
        .catch(function (error) {
          // 请求失败处理
          console.log(error);
        });
    },
    resetForm() {
      this.ruleForm.name='',
      this.ruleForm.code=''
      this.input='',
      this.SelectValue='',
      this.input3='',
      this.radio=1,
      this.submitForm(10,1)
    },
    handleSizeChange() {
      console.log(` items per page`, this.pageSize4, this.currentPage4);
      this.submitForm(this.pageSize4, this.currentPage4)
    },
    handleCurrentChange() {
      this.submitForm(this.pageSize4, this.currentPage4)
    },
    handleSizeChangeReacharge() {
      console.log(` items per page`, this.pageSize1, this.currentPage1);
      this.getData(this.reachagecompanyName, this.pageSize1, this.currentPage1)
    },
    handleCurrentChangeReacharge() {
      console.log(`current page:`, this.pageSize1, this.currentPage1);
      this.getData(this.reachagecompanyName, this.pageSize1, this.currentPage1)
    },
  },
  created() {
    this.submitForm(10,1)
  },
  data() {
    return {
      currentPage1:1,
      pageSize1:10,
      numberReachage:0,
      currentPageR:0,
      reachargedata:[],
      reachagecompanyName:'',
      gridData:[],
      dialogTableVisible:false,
      companyUserName: "",
      dialogVisibleRecharge: false,
      radio: 1,
      inputOut2: "",
      inputOut3: "",
      SelectValue: "",
      input2: "",
      input2Chinsese: "",
      title: "",
      input3: "",
      input3Chinsese: "",
      
      pageSize4: 10,
      currentPage4: 1,
      tableData: [],
      ruleForm: {
        code: "",
        name: "",
        sex: "",
        type: ""
     
      },
      company: "",
      options: [
        {
          value: "1",
          label: "现金",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "支付宝",
        },
        {
          value: "4",
          label: "银行卡",
        },
      ],
    };
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.colorBackground {
  background-color: white;
   height:38vw
}
.header {
  margin-left: 10px;
}

.el-row {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 20px;
}
.rechargeClass {
  position: relative;
  margin-bottom: 10px;
  margin-top: 10px;
}

.rechargeInput {
  display: flex;
  align-items: center;
  height: 30px;
}
.inputclass {
  margin-left: 20px;
}
.paginationClass {
  position: fixed;
  bottom: 0px;
  height: 40px;
  width: 100%;
  text-align: center;
  margin-left: 35%;
}
.container {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100px;
  display: flex;
  height: 30px; /* 容器的高度 */
}
</style>
    